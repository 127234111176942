<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3" v-if="createMode">
        <label for="fileInput" class="form-label">* Archivo</label>
        <input
          id="mainInputFile"
          class="form-control"
          type="file"
          name="fileInput"
          accept=".png,.jpg,.jpeg,.gif,.webp,.pdf"
          @change="checkFile($event.target)"
          :required="createMode"
        />
        <div class="invalid-feedback">
          Por favor seleccione un archivo
        </div>
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">* Nombre</label>
        <input
          type="text"
          class="form-control"
          name="name"
          :minlength="nameMin"
          :maxlength="nameMax"
          v-model="name"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese un nombre para el archivo
        </div>
      </div>
      <div class="mb-3 form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="priority"
          v-model="checked"
        />
        <label class="form-check-label" for="checked">
          ¿Este archivo es un documento controlado?
        </label>
      </div>
      <div class="mb-3" v-if="checked">
        <label for="expiration" class="form-label"
          >Este Documento Expira en</label
        >
        <select
          class="form-select text-capitalize"
          name="expiration"
          v-model="expiration"
        >
          <option value="month-1">1 Mes</option>
          <option value="month-3">3 Meses</option>
          <option value="month-6">6 Meses</option>
          <option value="year-1">1 Año</option>
          <option value="year-2">2 Años</option>
          <option value="year-3">3 Años</option>
          <option value="year-4">4 Años</option>
          <option value="year-5">5 Años</option>
        </select>
      </div>
      <div class="mb-3" v-if="checked">
        <label for="endDate" class="form-label"
          >El Documento Vencerá el Día</label
        >
        <input
          type="date"
          class="form-control"
          name="endDate"
          v-model="expirationDate"
          :required="checked"
        />
      </div>
      <div class="mb-3" v-if="checked">
        <label for="responsable" class="form-label"
          >* Responsable de Actualizar el Documento</label
        >
        <select
          class="form-select text-capitalize"
          name="responsable"
          v-model="responsable"
          :required="checked"
        >
          <option
            v-for="employee in employeeList"
            v-bind:value="employee._id"
            :key="employee._id"
            >{{ employee.name }}</option
          >
        </select>
      </div>
      <input type="hidden" name="extension" v-model="extension" />
      <input type="hidden" name="fileSize" v-model="fileSize" />
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-primary shadow-primary"
          @click.prevent="submit()"
        >
          {{ submitName }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      name: "",
      nameMin: 0,
      nameMax: 1,
      checked: false,
      expiration: "month-1",
      responsable: "",
      employeeList: [],
      extension: "",
      enableExtensions: [],
      fileSize: "",
      createMode: true
    };
  },
  mounted() {
    store.getData("employee").then(employeeList => {
      employeeList.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      employeeList.forEach(employee => {
        if (employee.branch === this.$route.params._id) {
          this.employeeList.push(employee);
        }
      });
      try {
        this.responsable = this.employeeList[0]._id;
      } catch (error) {
        employeeList.forEach(employee => {
          if (employee.branch === this.defaultData.branch) {
            this.employeeList.push(employee);
          }
          if (employee._id === this.defaultData.responsable) {
            this.responsable = employee._id;
          }
        });
      }
    });
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "file") {
          this.nameMin = schema.data.name.min;
          this.nameMax = schema.data.name.max;
          this.enableExtensions = schema.data.extension.enum;
        }
      });
      this.mounted = true;
    });
  },
  computed: {
    expirationDate: function() {
      const date = new Intl.DateTimeFormat("en").formatToParts();
      let month = date[0].value;
      let day = date[2].value;
      let year = date[4].value;

      if (this.expiration === "month-1") {
        if (month == 12) {
          month = 1;
          year++;
        } else {
          month++;
        }
      } else if (this.expiration === "month-3") {
        if (month > 9) {
          month = parseInt(month) + 3 - 12;
          year++;
        } else {
          month = parseInt(month) + 3;
        }
      } else if (this.expiration === "month-6") {
        if (month > 6) {
          month = parseInt(month) + 6 - 12;
          year++;
        } else {
          month = parseInt(month) + 6;
        }
      } else if (this.expiration === "year-1") {
        year++;
      } else if (this.expiration === "year-2") {
        year = parseInt(year) + 2;
      } else if (this.expiration === "year-3") {
        year = parseInt(year) + 3;
      } else if (this.expiration === "year-4") {
        year = parseInt(year) + 4;
      } else if (this.expiration === "year-5") {
        year = parseInt(year) + 5;
      }

      if (month < 10) {
        month = "0" + month.toString();
      }
      if (day < 10) {
        day = "0" + day.toString();
      }

      return year + "-" + month + "-" + day;
    }
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    },
    checkFile(fileInput) {
      const file = fileInput.files[0];

      let fileName = file.name.split(".");
      this.extension = fileName.pop();
      fileName = fileName.join(".");

      this.name = fileName;
      this.extension = this.extension.toUpperCase();

      this.fileSize = file.size;

      if (file.size > 2500000000) {
        Swal.fire({
          icon: "error",
          title: "Archivo no permitido",
          text: "El archivo no debe superar un tamaño de 4GB"
        }).then(() => {
          document.getElementById("mainInputFile").value = "";
          this.name = "";
        });
      }

      if (!this.enableExtensions.includes(this.extension)) {
        Swal.fire({
          icon: "error",
          title: "Archivo no permitido",
          text: "No se permite subir archivos con extensión: " + this.extension
        }).then(() => {
          document.getElementById("mainInputFile").value = "";
          this.name = "";
        });
      }
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.name !== undefined && val.name !== null) {
              this.name = val.name;
              this.createMode = false;
            }
            if (val.responsable !== undefined && val.responsable !== null) {
              this.checked = true;
              this.expiration = "";
              this.responsable = val.responsable;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Document Main Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
